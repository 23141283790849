import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import _ from 'lodash';
import { graphql } from 'gatsby';
import paginate from '@pelevesque/paginate';
import moment from 'moment';

import breakpoints from '../styles/breakpoints';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import { refineHTMLContent } from '../utils/browser';

const Card = styled.div`
  max-width: 700px;
  margin: 0 auto;
  padding: 0rem 1rem 5rem;

  @media (max-width: ${breakpoints.md}) {
    padding: 1.5rem 1rem;
  }
`;

const MainContent = styled.div`
  min-height: 100vh;
  background: rgb(245, 247, 250);
  padding-top: 5rem;
`;

const EmptyHeader = styled.header`
  padding: 2.5rem;
  font-size: 1.375rem;
  font-weight: 500;
  text-align: center;
  color: #343434;
  background-color: #ffffff;
  border-bottom: solid 1px #eceff0;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;

  @media (max-width: ${breakpoints.sm}) {
    padding: 1.5rem;
  }
`;

const Header = styled.header`
  padding: 2rem;
  padding-bottom: 1rem;
  font-size: 1.375rem;
  font-weight: 500;
  color: #343434;
  background-color: #ffffff;
  border-bottom: solid 1px #eceff0;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;

  @media (max-width: ${breakpoints.sm}) {
    padding: 1.5rem;
  }

  a {
    color: #343434;
  }
`;

const Body = styled.section`
  padding: 1.5rem 3rem;
  text-align: left;
  background-color: #ffffff;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  overflow: auto;

  @media (max-width: ${breakpoints.sm}) {
    padding: 1.5rem 2rem 2rem;
  }
`;

const Info = styled.div`
  text-align: right;
  font-size: 0.875rem;
  font-weight: normal;
  background: #ffffff;
  padding-top: 1rem;
`;

const ViewDetails = styled.div`
  padding: 1.5rem 3rem;
  text-align: center;
  background-color: #ffffff;
`;

const Pagination = styled.div`
  text-align: center;
  padding-bottom: 1.5rem;
`;

const PageButton = styled.a`
  width: 30px;
  display: inline-block;
  ${props => (props.active ? 'background: #ebeef3; cursor: default;' : `cursor: pointer;`)}

  :hover {
    background: #ebeef3;
  }
`;

const List = styled.div`
  ${Card} + ${Card} {
    padding-top: 0;
  }
`;

const PAGE_SIZE = 10;
const PAGE_LIMIT = 5;

function AllBlogsPage({ data }) {
  const allBlogs = _.filter(data.allTrangBlog.nodes, blog => !!blog.slug);
  const [blogsPage, setBlogsPage] = useState([]);
  const [pagination, setPagination] = useState({});

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const pageNumber = +urlParams.get('page') || 1;
    // const pageNumber = +useQueryParam('page', 1) || 1;
    const paging = paginate(PAGE_LIMIT, pageNumber, PAGE_SIZE, allBlogs.length, '?page=');
    setPagination(paging);

    setBlogsPage(
      _.slice(allBlogs, (paging.currentPage - 1) * PAGE_SIZE, paging.currentPage * PAGE_SIZE)
    );
  }, []);

  return (
    <Layout mainContent={MainContent}>
      <SEO title="Bài viết" />
      {(!allBlogs || allBlogs.length === 0) && (
        <Card>
          <EmptyHeader>Hiện chưa có bài viết nào. Bạn quay lại sau nhé!</EmptyHeader>
        </Card>
      )}
      <List>
        {blogsPage &&
          _.map(blogsPage, blog => (
            <Card key={blog.slug}>
              <Header>
                <a href={`/blogs/${blog.slug}`}>{blog.title}</a>
                <Info>
                  {`Lúc ${moment(blog.publishedDate).format('DD/MM/YYYY')} bởi ${
                    blog.author
                      ? blog.author.name || blog.author.email
                      : blog.createdBy.name || blog.createdBy.email
                  }`}
                </Info>
              </Header>
              <Body>
                <br />
                <div
                  dangerouslySetInnerHTML={{
                    __html: refineHTMLContent(blog.content.brief || blog.content.extended),
                  }}
                />
              </Body>
              <ViewDetails>
                <a href={`/blogs/${blog.slug}`}>Đọc thêm</a>
              </ViewDetails>
            </Card>
          ))}
      </List>
      <Pagination>
        {pagination.numPreviousPages > 0 && (
          <PageButton href={`?page=${pagination.numPreviousPages}`}>{`<`}</PageButton>
        )}
        {_.map(pagination.links, link => (
          <PageButton key={link.page} href={link.url} active={link.active}>
            {link.page}
          </PageButton>
        ))}
        {pagination.numFollowingPages > 0 && (
          <PageButton href={`?page=${pagination.numFollowingPages}`}>{`>`}</PageButton>
        )}
      </Pagination>
    </Layout>
  );
}

AllBlogsPage.propTypes = {
  data: PropTypes.shape({
    allTrangBlog: PropTypes.arrayOf({}),
  }).isRequired,
};

export default AllBlogsPage;

export const pageQuery = graphql`
  query {
    allTrangBlog {
      nodes {
        slug
        title
        state
        updatedAt
        updatedBy {
          email
        }
        publishedDate
        createdAt
        createdBy {
          email
        }
        content {
          brief
          extended
        }
      }
    }
  }
`;
